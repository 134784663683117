<template>
  <base-view title="Claves presupuestales" icon="key">
    <b-row>
      <b-col cols="12">
        <div class="card card-header-actions mb-4">
          <div class="card-header">
            Claves presupuestales

            <button
              class="btn btn-success btn-sm ml-auto"
              :disabled="disableCreate"
              @click="createClave"
            >
              Crear
            </button>
          </div>

          <div class="card-body">
            <check-authorization :requiresAuthorizations="['listar claves presupuestales']">
              <claves-presupuestales-table
                :create-new-clave="createNewClave"
                @on-creating="creating"
              />
            </check-authorization>
          </div>
        </div>
      </b-col>
    </b-row>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import ClavesPresupuestalesTable from '@/components/ClavesPresupuestales/ClavesPresupuestalesTable'

export default {
  name: 'ClavesPresupuestales',

  components: {
    ClavesPresupuestalesTable
  },

  computed: {
    ...mapGetters('clavesPresupuestalesModule', ['hastList', 'getList'])
  },

  data () {
    return {
      createNewClave: null,
      disableCreate: false
    }
  },

  methods: {
    createClave () {
      const claves = this.$store.state.clavesPresupuestalesModule.list.data.data
      const nuevaClave = {
        sp: '',
        spnf: '',
        se: '',
        sbe: '',
        ep: '',
        ra: '',
        un: '',
        fi: '',
        fu: '',
        sf: '',
        ms: '',
        oe: '',
        es: '',
        la: '',
        ai: '',
        mpp: '',
        pp: '',
        pc: '',
        ac: '',
        eg: '',
        tg: '',
        ff: '',
        parti: '',
        periodo_fiscal_id: 1,
        periodo_fiscal: {
          id: '',
          nombre: ''
        },
        isNew: true
      }
      claves.push(nuevaClave)

      this.createNewClave = nuevaClave
    },

    creating (isCreating) {
      this.disableCreate = isCreating
    }
  }
}
</script>
